import {Separator} from 'platform/components';
import {Heading, HStack, Icon, Space, Text, VStack} from 'platform/foundation';

import {AdaptiveImageDcNew} from '../../components/AdaptiveImageDcNew/AdaptiveImageDcNew';
import {Button} from '../../components/Button/Button';
import {CardOo} from '../../components/CardOo/CardOo';

export function SellerCard() {
  return (
    <CardOo backgroundColor="semantic.bg.subtle">
      <VStack width="100%">
        <HStack width="100%" spacing={5}>
          <VStack width="100%" maxWidth={18}>
            <AdaptiveImageDcNew ratio="1/1" fit="contain" url="./assets/images/person.png" />
          </VStack>
          <VStack width="100%" spacing={2}>
            <Heading size={4}>Marek Dvořák</Heading>
            <Text size="xSmall" color="secondary">
              Prodejce
            </Text>
            <Separator spacing={0} />
            <HStack width="100%" spacing={2}>
              <Icon size={5} value="social/telephone" />
              <Text size="xSmall">(+420) 777 666 777</Text>
            </HStack>
            <HStack width="100%" spacing={2}>
              <Icon size={5} value="social/email" />
              <Text size="xSmall">marek.dvorak@autogalaxy.com</Text>
            </HStack>
          </VStack>
        </HStack>
        <Space vertical={5} />
        <VStack maxWidth={[90, 90, '100%']} width="100%">
          <Button
            leftIcon="notification/phone"
            leftIconSize={4}
            title="Zavolejte mi"
            data-testid="sellerCard-call"
            variant="inverted"
          />
        </VStack>
      </VStack>
    </CardOo>
  );
}
