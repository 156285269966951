import {APPLICATION_NAME} from '@online-offer/config';
import {Box, Center, Show, Space, Stack, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Route, Routes} from 'react-router-dom';

import {Certificate} from './(sections)/Certificate/Certificate';
import {Details} from './(sections)/DetailsNew/Details';
import {DocumentationPrint} from './(sections)/Documentation/Documentation.print';
import {Error} from './(sections)/Error/Error';
import {ExteriorPrint} from './(sections)/Exterior/Exterior.print';
import {Features as FeaturesNew} from './(sections)/FeaturesNew/Features';
import {HeroPrint} from './(sections)/Hero/Hero.print';
import {HeroNew} from './(sections)/Hero/HeroNew';
import {InteriorPrint} from './(sections)/Interior/Interior.print';
import {MechanicsPrint} from './(sections)/Mechanics/Mechanics.print';
import {PhotoDocumentationPrint} from './(sections)/PhotoDocumentation/PhotoDocumentation.print';
import {SellerCard} from './(sections)/SellerCard/SellerCard';
import {ServiceHistoryPrint} from './(sections)/ServiceHistory/ServiceHistory.print';
import {Sidebar} from './(sections)/Sidebar/Sidebar';
import {SpecificationPrint} from './(sections)/Specification/Specification.print';
import {SpecificationNew} from './(sections)/SpecificationNew/SpecificationNew';
import {TechnicalParameters} from './(sections)/TechnicalParameters/TechnicalParameters';
import {TestDrivePrint} from './(sections)/TestDrive/TestDrive.print';
import {WheelsAndTiresPrint} from './(sections)/WheelsAndTires/WheelsAndTires.print';
import {ApplicationInitializeBoundary} from './components/ApplicationInitializeBoundary/ApplicationInitializeBoundary';
import {Footer} from './components/Footer/Footer';
import {NavigationNew} from './components/Navigation/NavigationNew';
import {getActiveSections, isSectionActive} from './components/Section/utils/getActiveSections';
import {useGetDataForWheelsAndTires} from './hooks/useGetDataForWheelsAndTires';
import {useGetOnlineOfferData} from './hooks/useGetOnlineOfferData';
import {useGetSectionsData} from './hooks/useGetSectionData';
import i18n from './i18n/i18n';

export function App() {
  return (
    <Routes>
      <Route element={<ApplicationInitializeBoundary />}>
        <Route path=":auditId/" element={<OnlineOffer />} />
        <Route path=":auditId/pdf" element={<OnlineOfferPrint />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
function OnlineOffer() {
  const {
    hiddenSections,
    sections,
    documentationData,
    mechanicsData,
    serviceHistoryData,
    testDriveData,
    featuresData,
    wheelsAndTiresData,
    photoDocumentationData,
    canBeSectionHide,
    detailsData,
    technicalParamsData,
  } = useGetSectionsData();
  const {auditId, ratings} = useGetOnlineOfferData();

  const num = Math.floor(Math.random() * 2);
  const deadline = new Date();
  // TODO: remove when implementing BE, testing purposes only
  if (num === 0) {
    deadline.setHours(deadline.getHours() + 2);
  }
  deadline.setSeconds(deadline.getSeconds() + 15);

  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s • ${APPLICATION_NAME}`} htmlAttributes={{lang: i18n.language}}>
        <meta name="description" content={APPLICATION_NAME} />
      </Helmet>

      <NavigationNew sections={sections} />

      <HeroNew />

      <Space vertical={[6, 6, 11]} />

      <Center>
        <Box flexGrow={1} paddingHorizontal={[3, 8, 20, 20]} maxWidth={440}>
          <Stack direction={['column-reverse', 'column-reverse', 'row']} spacing={8} width="100%">
            <VStack width="100%">
              <SpecificationNew />
              <Details data={detailsData} />
              <FeaturesNew {...featuresData} />
              <Certificate data={{digitalCertificateVehicleId: auditId, ratings}} />
              <TechnicalParameters data={technicalParamsData} />
              <Show onMobile onTablet>
                <SellerCard />
              </Show>
            </VStack>
            <VStack spacing={[6, 6, 8]} width={['100%', '100%', 90]} shrink={0}>
              <Sidebar deadline={deadline} />
            </VStack>
          </Stack>
        </Box>
      </Center>
      <Footer />
    </HelmetProvider>
  );
}

function OnlineOfferPrint() {
  const {vehicleAudit} = useGetOnlineOfferData();
  const locale = useLocale();
  const wheelsAndTiresData = useGetDataForWheelsAndTires(vehicleAudit, locale);

  const sections = getActiveSections(vehicleAudit?.auditStructure?.categories);

  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s • ${APPLICATION_NAME}`} htmlAttributes={{lang: i18n.language}}>
        <meta name="description" content={APPLICATION_NAME} />
      </Helmet>

      <Box maxWidth={278} width={278} backgroundColor="fill.white">
        <HeroPrint />
        <SpecificationPrint />
        <Show when={isSectionActive('EXTERIOR', sections)}>
          <ExteriorPrint />
        </Show>
        <Show when={isSectionActive('INTERIOR', sections)}>
          <InteriorPrint />
        </Show>
        <Show when={isSectionActive('TESTDRIVE', sections)}>
          <TestDrivePrint />
        </Show>
        <Show
          when={
            isSectionActive('WHEELS_AND_TIRES', sections) &&
            !wheelsAndTiresData.sectionConfig.isHidden
          }
        >
          <WheelsAndTiresPrint {...wheelsAndTiresData} />
        </Show>
        <Show when={isSectionActive('MECHANICS', sections)}>
          <MechanicsPrint />
        </Show>
        <Show when={isSectionActive('PHOTODOCUMENTATION', sections)}>
          <PhotoDocumentationPrint />
        </Show>
        <Show when={isSectionActive('VEHICLE_DOCUMENTATION', sections)}>
          <DocumentationPrint />
        </Show>
        <Show when={isSectionActive('SERVICE_HISTORY', sections)}>
          <ServiceHistoryPrint />
        </Show>
      </Box>
    </HelmetProvider>
  );
}
