import {
  AdaptiveImage,
  AdaptiveImageProps,
  Box,
  Center,
  Icon,
  makeStorageUrl,
} from 'platform/foundation';
import {useTheme} from 'styled-components';

export type AdaptiveImageDcProps = Omit<AdaptiveImageProps, 'makeUrl' | 'height' | 'width'> & {
  hasSpinner?: boolean;
};

const ASPECT_RATIO = '4 / 3';

export const AdaptiveImageDcNew = ({hasSpinner = true, ...props}: AdaptiveImageDcProps) => {
  const theme = useTheme();

  const ratio = props.ratio ?? ASPECT_RATIO;

  return (
    <Box width="100%" height="100%" ratio={ratio}>
      <AdaptiveImage
        {...props}
        ratio={ratio}
        makeUrl={makeStorageUrl}
        placeholder={
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            ratio={ratio}
            backgroundColor={theme.components.Image.emptyFill}
            borderRadius="small"
          >
            <Center width="100%" height="100%">
              <Icon color="palettes.neutral.70.100" value="image/photo" size={6} />
            </Center>
          </Box>
        }
      />
    </Box>
  );
};
